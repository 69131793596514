import { Api } from "../axios-config";

export interface IComission {
	id: string;
	invoice: string;
	partner: string;
	cycle: string;
	totalAmount: string;
	type: string;
	clientId: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	totAmount: string;
	totValue: string;
	reference: string;
	dueDate: string;
	paymentDate: string;
	cancelDate: string;
	cancelReason: string;
	cancelUser: string;
}

export type TComissionTotalCount = {
	data: IComission[];
	totalCount: number;
};

const getComission = async (page = 1, limit = 10, search = "", dataini = "", datafim = "", status = ""): Promise<TComissionTotalCount | Error> => {
	try {
		const { data } = await Api().get(`/comission?_page=${page}&_limit=${limit}&nome_like=${search}&dataini=${dataini}&datafim=${datafim}&status=${status}`);
		if (data.results) {
			return { data: data.results, totalCount: data.total };
		}
		return new Error("Erro ao buscar comissões");
	} catch (error) {
		console.error(error);
		return new Error("Erro ao buscar comissões");
	}
};

const getComissionByID = async (id: string): Promise<IComission | Error> => {
	try {
		const { data } = await Api().get(`/comission/values/${id}`);
		if (data) {
			return data;
		}
		return new Error("Erro ao buscar valores");
	} catch (error) {
		console.error(error);
		return new Error("Erro ao buscar valores");
	}
};

const getComissionDetail = async (id: string): Promise<IComission | Error> => {
	try {
		const { data } = await Api().get(`/comission/details/${id}`);
		if (data) {
			return data;
		}
		return new Error("Erro ao buscar valores");
	} catch (error) {
		console.error(error);
		return new Error("Erro ao buscar valores");
	}
};

const addValues = async (values: any): Promise<IComission | Error> => {
	try {
		const { data } = await Api().post(`/comission/addvalues`, values);
		if (data) {
			return data;
		}
		return new Error("Erro ao adicionar valor");
	} catch (error) {
		console.error(error);
		return new Error("Erro ao adicionar valore");
	}
};

const getComissionValues = async (id: string): Promise<IComission | Error> => {
	try {
		const { data } = await Api().get(`/comission/values/${id}`);
		if (data) {
			return data;
		}
		return new Error("Erro ao buscar valores ");
	} catch (error) {
		console.error(error);
		return new Error("Erro ao buscar valores");
	}
};

export const ComissionServices = {
	getComission,
	getComissionByID,
	getComissionValues,
	getComissionDetail,
	addValues,
};
