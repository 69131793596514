import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { globalFormatCurrencyBRL } from "../../../shared/utils";
import { formatDateFnsRemoveTimezone } from "../../../shared/utils/format-date";

interface TableResumeProps {
	rows: any[];
}

const translateType = (type: string) => {
	switch (type) {
		case "parcel":
			return "Parcela";
		case "discount":
			return "Estorno";
		case "bonus":
			return "Bonificação";
		case "prize":
			return "Premiação";
		default:
			return type;
	}
};

const TableResume: React.FC<TableResumeProps> = ({ rows }) => {
	const totalValue = rows.reduce((acc, invoice) => {
		const value = Number(invoice.totValue) || 0; // Converte para número e evita NaN
		return invoice.type === "discount" ? acc - value : acc + value;
	}, 0);

	return (
		<>
			<Typography variant="h6" component="div" sx={{ padding: 1, textAlign: "center", mb: 0, color: "#666" }}>
				Resumo
			</Typography>

			<TableContainer
				component={Paper}
				variant="outlined"
				sx={{
					marginTop: 0,
					width: "100%",
					padding: 0,
					maxHeight: 300,
					overflowY: "auto",
					color: "#666",
					"&::-webkit-scrollbar": { width: 8 },
					"&::-webkit-scrollbar-track": { background: "#f1f1f1", borderRadius: 8 },
					"&::-webkit-scrollbar-thumb": { backgroundColor: "#c1c1c1", borderRadius: 8, backgroundClip: "padding-box", border: "2px solid #f1f1f1" },
					"&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#b0b0b0" },
					scrollbarWidth: "thin",
					scrollbarColor: "#c1c1c1 #f1f1f1",
				}}
			>
				<Table stickyHeader size="small">
					<TableHead>
						<TableRow>
							<TableCell>Data</TableCell>
							<TableCell>Descrição / Plano</TableCell>
							<TableCell>Tipo</TableCell>
							<TableCell sx={{ textAlign: "center" }}>Quantidade</TableCell>
							<TableCell sx={{ textAlign: "right" }}>Valor Total</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((invoice) => (
							<TableRow key={invoice.id}>
								<TableCell>{formatDateFnsRemoveTimezone(invoice.createdAt)}</TableCell>
								<TableCell>{invoice.description}</TableCell>
								<TableCell>{translateType(invoice.type)}</TableCell>
								<TableCell sx={{ textAlign: "center" }}>{invoice.totCount}</TableCell>
								<TableCell sx={{ textAlign: "right" }}>
									{invoice.type === "discount" ? "-" : ""} {globalFormatCurrencyBRL(invoice.totValue)}
								</TableCell>
							</TableRow>
						))}

						{/* Linha extra para mostrar o total */}
						<TableRow>
							<TableCell colSpan={4} align="right" sx={{ fontWeight: "bold" }}>
								<Typography variant="subtitle1">Total:</Typography>
							</TableCell>
							<TableCell sx={{ textAlign: "right" }}>
								<Typography variant="subtitle1" fontWeight={500} color="primary">
									{globalFormatCurrencyBRL(totalValue)}
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default TableResume;
