import { Button, Modal, Box, Typography, Card, CardContent, Alert } from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import CropFreeIcon from "@mui/icons-material/CropFree";

interface ModalFileProps {
	visible: boolean;
	onClose: () => void;
}

// Ajuste da largura do modal principal
const style = {
	position: "relative",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "40%", // Reduzido de 50% para 40%
	height: "auto",
	maxWidth: "75%",
	maxHeight: "85%",
	bgcolor: "background.paper",
	border: "1px solid #605e5e",
	boxShadow: 24,
	borderRadius: "6px",
	display: "flex",
	flexDirection: "column",
	backgroundColor: "#FFF",
	padding: 3,
};

// Ajuste da largura do modal de visualização
const previewStyle = {
	position: "relative",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "70%", // Reduzido de 80% para 70%
	height: "80%",
	bgcolor: "background.paper",
	border: "1px solid #605e5e",
	boxShadow: 24,
	borderRadius: "6px",
	padding: 3,
};

const ModalFile: React.FC<ModalFileProps> = ({ visible, onClose }) => {
	const [file, setFile] = useState<File | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [preview, setPreview] = useState<string | null>(null);
	const [showPreview, setShowPreview] = useState<boolean>(false);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			const selectedFile = event.target.files[0];
			const validTypes = ["application/pdf", "image/png", "image/jpeg"];
			if (validTypes.includes(selectedFile.type)) {
				setFile(selectedFile);
				setError(null);
				setPreview(URL.createObjectURL(selectedFile));
				setShowPreview(false);
			} else {
				setError("Tipo de arquivo inválido. Permitidos: .pdf, .png, .jpeg, .jpg");
				setFile(null);
				setPreview(null);
				setShowPreview(false);
			}
		}
	};

	const handleRemoveFile = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setFile(null);
		setPreview(null);
		setShowPreview(false);
	};

	const handleSubmit = () => {
		if (file) {
			// Lógica para lidar com o arquivo anexado
			console.log("Arquivo anexado:", file);
		}
		onClose();
	};

	return (
		<>
			<Modal open={visible} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={style}>
					<Box display={"flex"} justifyContent={"center"}>
						<Typography variant="h5" paddingY={2} textAlign={"center"} color={"primary"}>
							Arquivos Anexos
						</Typography>
					</Box>
					<CardContent>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
							sx={{
								border: "2px dashed #ccc",
								padding: 3,
								borderRadius: 2,
								cursor: file ? "default" : "pointer",
								textAlign: "center",
							}}
							onClick={() => !file && document.getElementById("fileInput")?.click()}
						>
							{!file ? (
								<>
									<CloudUploadIcon fontSize="large" sx={{ color: "lightgray", fontSize: 56 }} />
									<Typography variant="h6" component="div" marginTop={2}>
										Clique aqui para anexar um arquivo
									</Typography>
									<Typography variant="body2" color="textSecondary">
										Tipos de arquivo permitidos: .pdf, .png, .jpeg, .jpg
									</Typography>
								</>
							) : (
								<>
									<Box display="flex" alignItems="center" marginTop={2}>
										<Typography variant="body1" component="div" marginRight={1}>
											{file.name}
										</Typography>
										<Button variant="text" color="primary" startIcon={<CropFreeIcon />} onClick={() => setShowPreview(true)}>
											Ver
										</Button>
									</Box>
									<Typography variant="body2" color="textSecondary">
										Tamanho: {(file.size / 1024).toFixed(2)} KB
									</Typography>
									<Button
										variant="text"
										color="error"
										startIcon={<DeleteIcon />}
										onClick={handleRemoveFile}
										sx={{ backgroundColor: "#ffebee", marginTop: 2 }}
									>
										Remover
									</Button>
								</>
							)}
							<input id="fileInput" type="file" accept=".pdf, .png, .jpeg, .jpg" onChange={handleFileChange} style={{ display: "none" }} />
						</Box>
						{error && (
							<Box marginTop={2}>
								<Alert severity="error">{error}</Alert>
							</Box>
						)}
						<Box display="flex" justifyContent="space-between" marginTop={2} width="100%">
							<Button onClick={onClose}>Fechar</Button>
							<Button variant="contained" onClick={handleSubmit} disabled={!file}>
								Enviar
							</Button>
						</Box>
					</CardContent>
				</Box>
			</Modal>

			<Modal open={showPreview} onClose={() => setShowPreview(false)} aria-labelledby="preview-modal-title" aria-describedby="preview-modal-description">
				<Box sx={previewStyle}>
					<Box display={"flex"} justifyContent={"center"}>
						<Typography variant="h5" paddingY={2} textAlign={"center"} color={"primary"}>
							Visualização do Arquivo
						</Typography>
					</Box>
					<Box marginTop={2} display="flex" justifyContent="center">
						{file?.type === "application/pdf" ? (
							<iframe src={preview || ""} width="100%" height="500px" title="PDF Preview"></iframe>
						) : (
							<img src={preview || undefined} alt="Preview" style={{ maxWidth: "100%", maxHeight: "500px" }} />
						)}
					</Box>
					<Box display="flex" justifyContent="center" marginTop={2}>
						<Button onClick={() => setShowPreview(false)}>Fechar</Button>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default ModalFile;
