import { Box, Button, CircularProgress, Grid, Icon, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ModalContext, ToastContent } from "../../../../shared/components";
import { useToast } from "../../../../shared/hooks";
import { clientService } from "../../../../shared/services/api/client/clientService";
import { PlanServices } from "../../../../shared/services/api/plan/PlanServices";
import { ModalPlan } from "../PlanComponents/ModalPlans";

interface ICommercialClientProps {
    isLoading: boolean;
    id: string;
}

export interface ICommercialGrid {
    id: string;
    status: string;
    planId: string;
    clientId: string;
    lmi: string;
    residentialFee: string;
    commercialFee: string;
    bonusAmount: string;
    productId: string;
    createdAt: string;
    updatedAt: string;
    flagAnalysis: string;
    flagRenovation: string;
    flagRateRent: string;
    flagRateCharge: string;
    coverage: string;
    setupTransfer: string;
    proposalMin: string;
    comissionProposalMin:string;
    comissionType:string;
    comissionValue:string;
    personalized: boolean;

    plan: {
        id: string;
        description: string;
    }
}

export const CommercialGridClient: React.FC<ICommercialClientProps> = ({
    id,
}) => {
    const [selectPlan, setselectPlan] = useState<ICommercialGrid>(
        {} as ICommercialGrid
    );

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [plan, setPlan] = useState<ICommercialGrid[]>([]);
    const [planId, setPlanId] = useState('');
    const toastHandler = useToast();
    const [isRefresh, setIsRefresh] = useState(true);
    const [openModalCommercial, setOpenModalCommercial] = useState(false);

    const getClientById = () => {
        if (id !== 'novo') {
            setIsRefresh(true);
            clientService.getByID(id)
                .then((result) => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        const commercial = result.__client_plan__ as unknown as ICommercialGrid[];
                        setPlan(commercial)
                        setIsRefresh(false);
                    }
                });
        }
    }

    const handleOpenModalCommercial = () => {
        setselectPlan({} as ICommercialGrid);
       // console.log('novo', selectPlan, plan);

        setOpenModalCommercial(true);
    }

    const handleConfirm = () => {
        setOpenModalCommercial(false);
        getClientById();
    }

    const handleDelete = () => {
        {
            planId ?
                PlanServices
                    .deleteById(planId)
                    .then((result) => {

                        if (result instanceof Error) {
                            setOpenModalConfirm(false);
                            toastHandler.present({
                                type: "error",
                                position: "top-right",
                                durationMs: 2000,
                                messageOrContent: (
                                    <ToastContent title={'Erro'} content={'Erro ao exluir o plano'} />
                                ),
                            });
                        } else {
                            setOpenModalConfirm(false);
                            toastHandler.present({
                                type: "success",
                                position: "top-right",
                                durationMs: 2000,
                                messageOrContent: (
                                    <ToastContent title={'Sucesso'} content={'Plano excluido com sucesso'} />
                                ),
                            });
                            setIsRefresh(true);
                        }
                    })
                : null
        }
    };

    useEffect(() => {
        if (isRefresh && !openModalConfirm && !openModalCommercial) {
            getClientById();
        }
    }, [isRefresh, openModalConfirm, openModalCommercial]);

    return (
        <>
            <Grid container item direction="row" spacing={2}>
                <Grid item md={12}>
                    <TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: 'auto', padding: 4 }} aria-label="customized table">
                        {isRefresh ? <TableRow> <TableCell sx={{ textAlign: "center" }} colSpan={4}><CircularProgress /> </TableCell> </TableRow> :
                            <Table aria-label="a dense table" >
                                <TableRow >
                                    <TableCell sx={{ color: "#7e7777b5" }}> Plano</TableCell>
                                    <TableCell sx={{ color: "#7e7777b5" }}> LMI</TableCell>
                                    <TableCell sx={{ color: "#7e7777b5" }}> Comercial</TableCell>
                                    <TableCell sx={{ color: "#7e7777b5" }}> Residencial</TableCell>
                                    <TableCell sx={{ color: "#7e7777b5" }}> Cobertura</TableCell>
                                    <TableCell sx={{ color: "#7e7777b5", padding: "1px" }}> Comiss.R$</TableCell>
                                    <TableCell sx={{ color: "#7e7777b5" }}> Cobrança </TableCell>
                                    <TableCell sx={{ color: "#7e7777b5" }}> Utilização</TableCell>
                                    <TableCell sx={{ color: "#7e7777b5" }}> </TableCell>
                                    {id !== 'novo' &&
                                        <Button
                                            color="primary"
                                            disableElevation
                                            variant="contained"
                                            onClick={handleOpenModalCommercial}
                                        >Adicionar</Button>}
                                </TableRow>
                                <TableBody>
                                    {plan?.map(row => (
                                        <TableRow key={row.id} hover >
                                            <TableCell sx={{ fontWeight: "normal" }}>
                                                <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                                                    {row.plan?.description}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "normal" }} >
                                                <Typography variant="subtitle2" fontWeight={"normal"} >
                                                    {row.lmi} x
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "normal" }} >
                                                <Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"} >
                                                    {row.commercialFee}%
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "normal" }} >
                                                <Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"} >
                                                    {row.residentialFee}%
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "normal" }} >
                                                <Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"} >
                                                    {row.coverage}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "normal" }} >
                                                <Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"} >
                                                    {row.bonusAmount}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "normal" }} >
                                                {row.flagRateRent == '1' ? 'Aluguel' : ''}
                                                {row.flagRateRent == '1' && row.flagRateCharge == '1' ? '/' : ''}
                                                {row.flagRateCharge == '1' ? 'Com Encargos' : ''}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "normal" }} >
                                                <Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"} >
                                                    {row.flagAnalysis == '1' ? 'Analises' : ''}
                                                    {row.flagAnalysis == '1' && row.flagRenovation == '1' ? '/' : ''}
                                                    {row.flagRenovation == '1' ? 'Renovação' : ''}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>
                                                <IconButton size="small"
                                                    onClick={() => {
                                                        setPlan([row]);
                                                        setPlanId(row.id);
                                                        setOpenModalConfirm(true);
                                                    }} sx={{ borderRadius: "18px" }}
                                                >
                                                    <Icon sx={{ color: "#D72802", fontWeight: "normal" }}> remove_done_Outlined_icon</Icon>
                                                    <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#D72802", fontWeight: "normal" }}>
                                                        Remover
                                                    </Typography>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setselectPlan(row);
                                                        //console.log('editar', row)
                                                        setOpenModalCommercial(true);
                                                        //     setPlan([row]);
                                                        //     setPlanId(row.id);
                                                        //     setOpenModalConfirm(true);
                                                    }}
                                                    sx={{ borderRadius: '18px' }}
                                                >
                                                    <Icon sx={{ color: '#0170B3', fontWeight: 'normal' }}>
                                                        {' '}
                                                        app_registrationicon
                                                    </Icon>
                                                    <Typography
                                                        variant="subtitle2"
                                                        marginLeft={1}
                                                        sx={{ color: '#0170B3', fontWeight: 'normal' }}
                                                    >
                                                        Editar
                                                    </Typography>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        }
                    </TableContainer>
                </Grid>
            </Grid>

            {openModalConfirm && <ModalContext
                isCloseModal={() => setOpenModalConfirm(false)}
                isOpenModal={openModalConfirm}
                messageType={'alert'}
                ITitle="Confirma a exclusão do plano ?"
                showButtonCancel={false}
                textButtonConfirm={'Excluir'}
                textButtonCancel={"Cancelar"}
                IMessage={[
                    <Box display={'flex'} flexDirection={'column'} padding={1} gap={2}  >
                        <Typography variant="subtitle1" sx={{ color: "#7e7777b5" }}>
                            Ao excluir o plano, o mesmo será removido da lista.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: "#7e7777b5" }}>
                            {plan.map((row) => (
                                <Box display={'flex'} gap={3} border={'1px solid #dcdcdd'} padding={1} borderRadius={2} justifyContent={'center'} >
                                    <Typography variant="subtitle1" sx={{ color: "#7e7777b5" }}>
                                        <strong>Plano:</strong> {row.plan?.description}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ color: "#7e7777b5" }}>
                                        <strong>LMI:</strong> {row.lmi} X
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ color: "#7e7777b5" }}>
                                        <strong>Taxa:</strong> {row.residentialFee}%
                                    </Typography>
                                </Box>
                            ))}
                        </Typography>
                        <Box display={'flex'} padding={1} gap={2} justifyContent={'center'} >
                            <Button
                                color="inherit"
                                disableElevation
                                variant="contained"
                                onClick={() => {
                                    setOpenModalConfirm(false);
                                    setIsRefresh(true);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                color="primary"
                                disableElevation
                                variant="contained"
                                onClick={handleDelete}
                            >
                                Excluir
                            </Button>
                        </Box>
                    </Box>
                ]}
            />}


            {openModalCommercial && <ModalPlan
                isCloseModal={() => setOpenModalCommercial(false)}
                isOpenModal={openModalCommercial}
                textButtonConfirm={selectPlan.id ? 'Editar' : 'Adicionar'}
                messageType={'info'}
                onclickConfirm={handleConfirm}
                data={selectPlan}
                clientPlan={id}
                onclickCancel={() => setOpenModalCommercial(false)}
            />}
        </>
    )
}